<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系統管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/sysManagement/sysUserManagement' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增用户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always" class="add-container">
      <div class="title">新增用户</div>
      <div class="container">
        <el-form ref="userForm" class="user-form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="用户账号" prop="account">
            <el-input v-model.trim="form.account" maxlength="64"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model.trim="form.userName" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="password">
            <el-input v-model.trim="form.password" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="角色名称" prop="relatedRole">
            <el-select v-model="form.relatedRole" multiple placeholder="请选择" style="width: 100%">
              <el-option v-for="item in options" :key="item.roleId" :label="item.name" :value="item.roleId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-dialog v-model="dialogFormVisible" title="用户信息" :width="'500px'">
        <el-form :model="form" class="user-info-form">
          <el-form-item label="用户名" :label-width="formLabelWidth">
            {{ userInfo.userName }}
          </el-form-item>
          <el-form-item label="userId" :label-width="formLabelWidth">
            {{ userInfo.userId }}
          </el-form-item>
          <el-form-item label="DNO" :label-width="formLabelWidth">
            {{ userInfo.dno }}
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer" style="text-align: center">
            <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
            <el-button type="primary" @click="confirmDNO" size="small">确认</el-button>
            <el-button class="btn re-check" @click="reCheck" size="small">重新校验</el-button>
          </div>
        </template>
      </el-dialog> -->

      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  //getCurrentInstance,
  onMounted,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { getAllBackRole, addBackUser } from "../../../api/sysManagement/user";
import md5 from 'js-md5';
export default {
  setup() {
    const userForm = ref();
    //const { proxy } = getCurrentInstance();
    const state = reactive({
      options: [],
      form: {
        account: "",
        userName: "",
        password: "",
        DNO: "",
        relatedRole: [],
        relatedWarehouse: [],
        instructionWarehouse: [],
      },
      rules: {
        account: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
        ],
        relatedRole: [
          { required: true, message: "请选择关联角色", trigger: "change" },
        ],
        relatedWarehouse: [
          {
            type: "array",
            required: true,
            message: "请选择关联操作仓",
            trigger: "change",
          },
        ],
        instructionWarehouse: [
          {
            type: "array",
            required: true,
            message: "请选择关联指令仓",
            trigger: "change",
          },
        ],
      },
      warehouseList: [],
      instructionList: [],
      dialogFormVisible: false,
      formLabelWidth: 120,
      DNOState: "",

      userInfo: {},
      //hasChecked: false
    });

    const router = useRouter();

    watch(
      () => state.form.DNO,
      (DNO, preDNO) => {
        if (DNO != preDNO) {
          state.DNOState = "";
          //state.hasChecked = false;
        }
      }
    );

    onMounted(() => {
      //getWarehouseList();
      //getInstructionList();
      getRoleList();
    });

    // const getWarehouseList = () => {
    //   proxy
    //     .$get("/cts/back/tenantManage/allTenantList")
    //     .then((res) => {
    //       if (res.code === "200") {
    //         state.warehouseList = res.data;
    //       }
    //     })
    //     .catch((err) => { });
    // };
    // const getInstructionList = () => {
    //   proxy
    //     .$get("/cts/back/instruction/allInstructionList")
    //     .then((res) => {
    //       if (res.resultState === "200") {
    //         state.instructionList = res.data;
    //       }
    //     })
    //     .catch((err) => { });
    // };

    const getRoleList = () => {
      getAllBackRole().then((res) => {
        if (res.resultState === "200") {
          state.options = res.data;
        }
      }).catch((err) => {

      });
      // proxy
      //   .$get("/cts/back/role/allRoleList")
      //   .then((res) => {
      //     if (res.code === "200") {
      //       state.options = res.data;
      //     }
      //   })
      //   .catch((err) => { });
    };

    //校验DNO
    // const checkDNO = () => {
    //   if (!state.form.DNO) {
    //     ElMessageBox.alert("请填写微态DNO", "提示", {
    //       confirmButtonText: "确定",
    //     });
    //     return;
    //   }
    //   proxy
    //     .$get(`/cts/back/customer/bindMicroStateAccount?dNo=${state.form.DNO}`)
    //     .then((res) => {
    //       if (res.resultState == 200) {
    //         state.dialogFormVisible = true;
    //         state.userInfo = res.data;
    //       }
    //     })
    //     .catch((err) => { });
    // };

    //重新校验
    // const reCheck = () => {
    //   state.form.DNO = "";
    //   state.DNOState = "";
    //   state.dialogFormVisible = false;
    // };

    //确认校验状态
    // const confirmDNO = () => {
    //   // state.hasChecked = true;
    //   state.DNOState = "校验成功";
    //   state.dialogFormVisible = false;
    // };

    const submit = () => {
      // //判断DNO有没有填写，填写的话，需要先校验；没有的话，可以直接提交
      // if (state.form.DNO && !state.DNOState) {
      //   // if (state.hasChecked) {
      //   //   ElMessageBox.alert('请重新填写关联微态DNO', '提示', {
      //   //     confirmButtonText: '确定'
      //   //   });
      //   // } else {
      //   ElMessageBox.alert("请校验关联微态DNO", "提示", {
      //     confirmButtonText: "确定",
      //   });
      //   //}
      //   return;
      // }
      // let tenantIdList = [];
      // state.warehouseList.forEach((item) => {
      //   if (state.form.relatedWarehouse.indexOf(item.tenantName) > -1) {
      //     tenantIdList.push(item.tenantId);
      //   }
      // });
      // let instructionIdList = [];
      // state.instructionList.forEach((item) => {
      //   if (
      //     state.form.instructionWarehouse.indexOf(item.instructionName) > -1
      //   ) {
      //     instructionIdList.push(item.instructionId);
      //   }
      // });
      let data = {
        account: state.form.account,
        avatar: "",
        certificateId: "",
        createMan: "",
        createManName: "",
        createTime: "",
        email: "",
        password: md5(state.form.password),
        phone: "",
        position: "",
        qq: "",
        remarks: "",
        roleId: state.form.relatedRole.toString(),
        sex: "",
        updateMan: "",
        updateManName: "",
        updateTime: "",
        userId: "",
        userName: state.form.userName,
        userNum: "",
        wechat: "",
        // account: state.form.account,
        // customerName: "",
        // dno: state.form.DNO,
        // microStateUserId: state.userInfo.userId || "",
        // microStateUsername: state.userInfo.userName || "",
        // nickName: state.form.nickname,
        // password: state.form.password,
        // phone: "",
        // roleId: state.form.relatedRole.toString(),
        // sex: "",
        // tenantId: tenantIdList.toString(),
        // instructionId: instructionIdList.toString(),
      };
      userForm.value.validate((valid) => {
        if (valid) {
          addBackUser(data).then((res) => {
            if (res.code === "200") {
              ElMessage({
                message: `${res.msg}`,
                type: "success",
              });
              router.push({ path: "/sysManagement/sysUserManagement" });
            }
          }).catch((err) => {

          });
          // proxy
          //   .$post("/cts/back/customer/add", data)
          //   .then((res) => {
          //     if (res.code === "200") {
          //       ElMessage({
          //         message: `${res.msg}`,
          //         type: "success",
          //       });
          //       router.push({ path: "/userManagement" });
          //     }
          //   })
          //   .catch((err) => { });
        }
      });
    };
    const cancel = () => {
      router.push({ path: "/sysManagement/sysUserManagement" });
    };
    return {
      ...toRefs(state),
      submit,
      cancel,
      userForm,
      // checkDNO,
      // reCheck,
      // confirmDNO,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  .title {
    font-weight: bold;
  }
  .user-form {
    width: 450px;
  }
  .container {
    padding: 20px;
    &__item {
      display: flex;
      align-items: center;
      &.item {
        margin-top: 15px;
      }
    }
    &__title {
      width: 100px;
    }
    &__input {
      width: 280px;
    }
  }
  .btn-group {
    display: flex;
    padding-left: 50px;
    .btn {
      width: 100px;
      text-align: center;
      background: rgb(56, 162, 138);
      padding: 6px 0;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      &.cancel {
        margin-left: 20px;
        background: #fff;
        color: #000;
        border: 1px solid #ddd;
      }
    }
  }
  .dno-state {
    color: #38a28a;
    font-weight: bold;
  }
  .re-check {
    background: #38a28a;
    color: #fff;
  }
  .user-info-form {
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
